.modal-forms {
    background-color: rgba(32,21,64,0.95);
    .modal-content {
        height: 100vh;
        border-radius: 0;
        border: none;
    }

    .modal-dialog {
        margin: 0 auto;
    }
    .modal-container {
        margin: auto 0;
    }
    
    .modal-header {
        position: relative;
        border-bottom: none;
        margin: 0 auto;

        .close {
            position: absolute;
            top: -16px;
            right: 30px;
            width: 24px;
            height: 24px;
            color: $white;
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            text-align: center;
            border-radius: 50%;
            background-color: $primary;
            padding: 0;
            margin: 0;
            opacity: 1;

            &:hover {
                font-size: 23px;
                opacity: .95;
            }
        }

        .modal-title {
            color: $accent;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0 auto;
        }
    }
}