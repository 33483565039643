.card-group-accuracy {
  background-color: $primary;
  color: #ffffff;

  h3 {
    color: #ffffff;
    font-size: 18px;
  } 
  
  h4 {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 0;
  }

  .oval-correct {
    box-sizing: border-box;
    float: left;
    margin-right: 5px;
    margin-top: 3px;
    height: 12px;
    width: 12px;
    border: 1px solid #FFFFFF;
    background-color: #03DAC5;
    border-radius: 50%;
  }

  .oval-incorrect {
    box-sizing: border-box;
    float: left;
    margin-right: 5px;
    margin-top: 3px;
    height: 12px;
    width: 12px;
    border: 1px solid #FFFFFF;
    background-color: #FF8181;
    border-radius: 50%;
  }

  .oval-friendly {
    float: left;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 6px 5px 0;
    border: 1px solid #ffffff;
    background-color: #FFEC79;
  }

  .oval-single {
    float: left;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 6px 5px 0;
    border: 1px solid #ffffff;
    background-color: #78E7FE;
  }

  .oval-teams {
    float: left;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 6px 5px 0;
    border: 1px solid #ffffff;
    background-color: #C0F997;
  }

  .bg-friendly {
    background-color: #FFEC79;
  }

  .bg-single {
    background-color: #78E7FE;
  }

  .bg-teams {
    background-color: #C0F997;
  }
}