@import '../../../scss/theme/helpers/colors';

.modal-form-campaign {
  background-color: rgba(32,21,64,0.95);
  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-close {
    color: #ffffff;
    .btn-link {
      color: #ffffff;
      &:hover {
        color: #ffffff;
      }
    }
  }

  .modal-header {
    display: block;
    color: #ffffff;
    background-color: $primary;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .modal-title {
    h4 {
      font-size: 20px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .wizard-indicators {
    list-style: none;

    li {
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 100%;
      position: relative;
      margin-right: 3px;
      margin-left: 3px;
      background-color: rgba($color: #fff, $alpha: .5);
      text-indent: -999px;

      &.active {
        background-color: #ffffff;
      }
    }
  }

  .modal-footer {
    .actions {
      .btn {
        margin: 0 5px;
      }
    }
  }
}