.wrap-auth {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(32,21,64,0.95);
}

.card-auth {
  width: 640px;
  max-width: 100%;
  border: none;
  border-radius: 0;

  .card-header {
    background-color: $primary;
    color: #ffffff;
    border-radius: 0;

    small {
      font-size: 12px;
    }

    .btn-link {
      color: #ffffff;

      &:hover {
        color: rgba(#fff, .75);
      }
    }
  }

  h2 {
    color: $white;
    font-size: 22px;
    margin-top: 0.75rem;
    text-transform: uppercase;
  }

  .footer-copy {
    padding-top: 80px;
    font-size: 10px;

    a {
      text-decoration: underline;
    }
  }

  .pass-true {
    color: $success;
  }

  .title-success {
    color: $primary;
    font-size: 22px;
    text-transform: uppercase;
  }
}

.login-actions {
  margin-top: 2.5rem;
}

.created-account-success {
  h2 {
    color: $primary;
  }
}
