.notifications{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.notifications-header {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: $primary;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.notifications-list {
  li {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    &:hover {
      background-color: $gray-200;
    }
  }
}

.notifications-detail {
  padding: 20px 18px;
}

.notifications-detail--title {
  display: flex;
  align-items: flex-start;

  h4 {
    font-size: 16px;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
  }
}

.notifications-detail--desc {
  padding: 18px 10px 18px 30px;
  font-size: 14px;

  h5 {
    font-size: 14px;
  }
}

.notifications-form {
  padding: 20px 18px;
}

.notifications-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid $gray-400;

  .btn {
    text-align: left;
    border-radius: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;

    .material-icons {
      float: right;
    }
  }
}