$pd-page-h: 0px;
$pd-page-v: 0;
$pd-content-h: 10px;

#wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $gray-100;
}

.page-content {
  padding: 30px 0;
}