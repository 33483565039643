@import './theme/helpers/variables';
@import './theme/helpers/mixins';
/* Bootstrap styles */
@import './bootstrap/bootstrap.scss';
/* Theme Academy styles */
@import './theme/reboot';
@import './theme/layout';
@import './theme/page-section';
// @import './theme/forms';
@import './theme/components/modal';
@import './theme/groups';
@import './theme/general';
@import './theme/nav';

@import './theme/components/typo';
@import './theme/components/header';
@import './theme/components/cards';
@import './theme/components/icons';
@import './theme/components/buttons';
@import './theme/components/navbars';
@import './theme/components/searchbar';
@import './theme/components/dropdown';
@import './theme/components/forms';
@import './theme/components/list-group';
@import './theme/components/progress';
@import './theme/components/avatar';
@import './theme/components/upload-image';
@import './theme/components/modal-associate-quiz';
@import './theme/components/wizard-campaign';
@import './theme/components/list-select-quizzes';
@import './theme/components/campaign-ranking';
@import './theme/components/widgets';
@import './theme/components/market';
@import './theme/components/auth';
@import './theme/components/sidebar';
@import './theme/components/notifications';
@import './theme/components/profile';
@import './theme/components/card-group-accuracy';
@import './theme/components/onboarding';
@import './theme/components/stepper';