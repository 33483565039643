.sidebar-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  opacity: 0;
  background-color: rgba(#000000, .75);
  transition: all 0.5s ease-in-out;

  &.active {
    display: block;
    opacity: 1;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  width: 320px;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  transition: all 0.3s;

  @media (min-width: 992px) {
    width: 470px;
  }
}

.sidebar-left {
  left: -320px;

  &.active {
    left: 0;
  }

  @media (min-width: 992px) {
    left: -470px;
  }
}

.sidebar-right {
  right: -320px;

  &.active {
    right: 0;
  }

  @media (min-width: 992px) {
    right: -470px;
  }
}