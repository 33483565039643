.modal-type-event {
  .modal-content {
    border: none;
    border-radius: 0;
  }
  .modal-header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0;

    h3 {
      font-size: 18px;
      color: #fff;
      margin-bottom: 0.4rem;
    }

    span {
      font-size: 13px;
    }
  }
  .modal-body {
    padding: 2.3rem;
  }
  .card {
    height: 100%;
    text-align: center;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
   
    &:hover {
      border: 2px solid #7E53FF;
      cursor: pointer;
    }

    h4 {
      font-size: 20px;
    }

    p {
      font-size: 12px;
    }

    .material-icons {
      font-size: 60px;
    }
  }
}