.modal-form-events {
  .modal-content {
    border-radius: 0;
    border: none;
  }

  .modal-header {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #ffffff;
    border-radius: 0;

    h1, h2, h3, h4, h5, h6 {
      color: #ffffff;
      text-transform: uppercase;
    }

    .modal-close {
      width: 100%;
    }

    .modal-header-title {
      width: 100%;
    }

    .modal-header-tools {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .modal-close {
    .btn-link {
      color: #ffffff;
      padding: 0 0;

      .material-icons {
        margin-right: 8px;
      }
    }
  }

  .bullets-indicators {
    list-style: none;

    li {
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 100%;
      position: relative;
      margin-right: 3px;
      margin-left: 3px;
      // background-color: rgba($color: #fff, $alpha: .5);
      text-indent: -999px;
      border: 1.5px solid #ffffff;

      &.active {
        &::after {
          content: '';
          width: 6px;
          height: 6px;
          background-color: #ffffff;
          position: absolute;
          top: 2px;
          left: 3px;
          border-radius: 100%;
        }
      }
    }
  }
}