.widget-campaign-stats {
  border-color: $primary;
  margin-bottom: 1.15rem;
  box-shadow: 0 2px 2px 0 rgba(126, 83, 255, 0.14), 0 3px 1px -2px rgba(126, 83, 255, 0.12), 0 1px 5px 0 rgba(126, 83, 255, 0.2);

  .stat-val {
    color: $accent;
    font-size: 1.4rem;
    display: block;
  }

  .stat-desc {
    color: $primary;
    font-size: .7rem;
  }
}