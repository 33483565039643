/*Utilities in accordance with material design icons guidelines*/

.material-icons {
  /* Sizing */
  &.md-18 { font-size: 18px; }
  &.md-18 { font-size: 20px; }
  &.md-24 { font-size: 24px; }
  &.md-30 { font-size: 30px; }
  &.md-36 { font-size: 36px; }
  &.md-48 { font-size: 48px; }

  /* Coloring */
  &.md-dark { color: rgba(0, 0, 0, 0.54); }
  &.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

  &.md-light { color: rgba(255, 255, 255, 1); }
  &.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }
}

h1 .material-icons, h2 .material-icons, h3 .material-icons, 
h4 .material-icons, h5 .material-icons, h6 .material-icons {
  line-height: inherit;
  float: left;
  margin-right: 6px;
}