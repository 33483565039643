/*Material inputs*/
.fg-line {
  .form-control {
    height: 35px;
    font-size: 16px;
    background-color: rgba($color: $black, $alpha: 0);
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    transition: background 0s ease-out 0s;
    border-radius: 0;

    &:focus {
      background-size: 100% 2px, 100% 1px;
      outline: 0 none;
      transition-duration: 0.3s; 
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .focused {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    transition-duration: 0.3s; 
  }
  
  .form-control, 
  .form-control:focus {
    background-image: linear-gradient($primary, $primary), linear-gradient(#d9d9d9, #d9d9d9);
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none; 
  }
}

/* Form group input */
.fg-line {
  .input-group-prepend {
    .input-group-text {
      font-weight: 500;
      padding: 0 10px;
      border: none;
      border-radius: 0;

      .material-icons {
        font-size: 1.3rem;
        line-height: inherit;
      }
    }
  }
}

/* React Select Material Design style */
.fg-line-select-container {
  .fg-line-select__control {
    background-color: rgba($color: $black, $alpha: 0);
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    transition: background 0s ease-out 0s;
  }

  .fg-line-select__control, 
  .fg-line-select__control--is-focused {
    background-image: linear-gradient($primary, $primary), linear-gradient(#d9d9d9, #d9d9d9);
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none; 
  }

  .fg-line-select__control--is-focused {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    transition-duration: 0.3s; 
  }

  .fg-line-select__value-container {
    padding-left: 0;
  }

  .fg-line-select__indicators {
    .fg-line-select__indicator-separator {
      width: 0;
    }

    .fg-line-select__indicator {
      color: $primary;
    }
  }

  .fg-line-select__control--is-disabled {
    .fg-line-select__indicators {
      .fg-line-select__indicator {
        color: $gray-600;
      }
    }
  }

  .fg-line-select__menu {
    border-radius: 0;
    border: none;
  }

  .fg-line-select__menu-list {
    .fg-line-select__option {
      position: relative;
      border-bottom: 1px solid $gray-400;
      padding-left: 34px;
      padding-right: 10px;
      &:last-child {
        border-width: 0;
      }

      &::before {
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        left: 10px;
        top: 10px;
        border: 2px solid $primary;
        border-radius: 50%;
        background-color: transparent;
        transition: border-color .2s,background-color .2s;
      }

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primary;
        position: absolute;
        top: 15px;
        left: 15px;
        opacity: 0;
      }
    }

    .fg-line-select__option--is-focused {
      color: $white;
      background-color: rgba($color: $primary, $alpha: 0.9);
      &::before {
        border-color: $white;
      }
    }

    .fg-line-select__option--is-selected {
      font-weight: 600;
      background-color: $primary;
      &::before {
        border-color: $white;
      }
      &::after {
        background-color: $white;
        opacity: 1;
      }
    }
  }

  .fg-line-select__control--is-disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.fg-line-datepicker {
  position: relative;
  padding-left: 35px;

  .material-icons {
    font-size: 1.3rem;
    line-height: inherit;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 5px;
    color: #FF537E;
  }
}

/*Utilities*/
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #9e9e9e;
  font-size: .85rem;

  .tf-count {
    float: right;
    color: #9e9e9e;
  }
}

.has-error {
  .help-block {
    color: $red;
  }
}

.has-warning {
  .help-block {
    color: $yellow;
  }
}

/* Custom elements */
.check-ok {
  height: 24px;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+label .no-check {
    display: inline-block;

    color: $primary;
    cursor: pointer;
  }

  input[type="checkbox"]+label .check {
    display: none;

    color: $primary;
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="checkbox"]:checked+label .no-check {
    display: none;
  }

  input[type="checkbox"]:checked+label .check {
    display: inline-block;
  }
}

/* Custom checkbox */
.custom-control {
  min-height: 1.7rem;
  padding-left: 1.7rem;
  cursor: pointer;
}

.custom-control-label::after {
  position: absolute;
  top: 0.23rem;
  left: -1.7rem;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  content: "";
  background: no-repeat 50% / 70% 70%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url('../../../assets/images/foursquare-check-in.svg');
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.7rem;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: $gray-600 solid 2px;
  box-shadow: 0 0 0 0 transparent;
}

.custom-control-label {
  &:hover {
    cursor: pointer;
  }
}

/* Custom radio */
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #7E53FF;
  background-color: #ffffff;
  box-shadow: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $primary;
  background-image: none;
  border-radius: 50%;
}

.custom-radio .custom-control-label::after {
  width: .58rem;
  height: .58rem;
  top: 0.54rem;
  left: -1.38rem;
}