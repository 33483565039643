.wrap-onboarding {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .accordion {
        .card-header {
            border-bottom: 0;
        }
    }

    .card-viewer {
        .card-main-header {
            display: flex;
            align-items: center;
            border-bottom: 0;
            padding: 20px 0;

            h1 {
                flex: 1;
                font-size: 22px;
                text-transform: uppercase;
                color: $purple;
                font-size: 600;
                margin-bottom: 0;
                margin-left: 11px;
            }
        }

        h4,
        h5 {
            color: #ACACAC;
        }

        h5 {
            font-size: 14px;
        }

        h4 {
            font-size: 16px;
        }

        h2 {
            color: $purple;
            font-weight: 600;
            font-size: 20px;
        }
    }

    .card-message {
        margin: 15px;

        p,
        h4 {
            color: $purple;
        }

        p {
            font-size: 14px;
            margin-bottom: 0px;
        }

        h4 {
            font-size: 22px;
            text-transform: uppercase;
        }
    }

    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
        margin: 0px 0px;
    }

    .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .review {
        padding-bottom: 40%;
    }
}

.ondas-bg {
    background-image: url("../../../assets/images/lineasOndas.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}