.sidebar-profile {
  .sidebar {
    width: 340px;
    background-color: $primary;
    color: #ffffff;
  }
}

.profile-userpic {
  position: relative;
  width: 100%;
  height: 180px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .btn-profile-back {
    position: absolute;
    left: 15px;
    top: 15px;
    color: $primary;
    border: 1px solid $primary;
    padding: 5px;
    background-color: #ffffff;
  }
}

.profile-userinfo,
.profile-useractions {
  padding: 22px 10px;
  .list-group-item {
    background-color: transparent;
    border-radius: 0;
    border: none;
  }

  .list-group-item-avatar {
    min-width: 36px;
  }
}

.profile-useractions {
  .list-group-item {
    &:hover {
      cursor: pointer;
      background-color: rgba(#000, 0.1);
    }
  }
}