.header {
  background-color: $white;
  position: relative;

  .topbar {
    padding: 1rem 0;
    .logo {
      float: left;
      padding: 10px 15px;
      img {
        width: 116px;
      }
    }

    .list-inline {
      .list-inline-item {
        padding: 8px 10px;
      }
    }

    .nav-user {
      border: none;
      border-radius: 0;
      background-color: transparent;
      img {
        width: 32px;
        height: 32px;
        border: 2px solid $primary;
      }

      &:hover {
        img {
          border-width: 2px;
          opacity: 0.8;
        }
      }
    }
  }
}

.header-actions {
  float: right;

  & > li {
    float: left;

    & > a {
      padding: 8px 20px 8px 20px;
      display: block;
      position: relative;
    }

    & > a.user-settings {
      padding: 0 0 0 18px;

      & .avatar {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        line-height: 40px;
        display: inline-block;
        text-align: center;
    
        & img {
          width: 38px;
          height: 38px;
          border-radius: 50px;
          border: 2px $primary solid;
        }
      }
    }


  }
}