//== Colors
//
// Gray and brand colors to use across the Theme.

// Grayscale
$white:                 #fff !default;
$gray-100:              #f9f9f9 !default;
$gray-200:              #f3f5f9 !default;
$gray-300:              #e3e9ef !default;
$gray-400:              #e0e3e6 !default;
$gray-500:              #b8babd !default;
$gray-600:              #8c8a8e !default;
$gray-700:            	#656566 !default;
$gray-800:              #4d4e4e !default;
$gray-900:              #3f3f41 !default;
$black:                 #000 !default;


// Brand colors
$primary:               #7E53FF !default;
$accent:                #FF537E !default;
$secondary:             $gray-200 !default;
$info:		              #69b3fe !default;
$success:               #42d697 !default;
$warning:               #fea569 !default;
$danger:                #f34770 !default;
$light:                 $white !default;
$dark:                  $gray-800 !default;