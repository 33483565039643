.card-mk-quiz {
  border-radius: 0;
  border: none;
  background-color: transparent;

  .card-img-top {
    border-radius: 0;
  }

  .card-img {
    border-radius: 0;
  }

  .card-mk-quiz--title {
    font-size: 18px;
    font-weight: 600;
  }

  .card-mk-quiz--level {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: .8rem;
  }

  .card-mk-quiz--info {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    color: #231343;
    margin-bottom: .6rem;

    &>div {
      flex: 0 0 50%;
      max-width: 50%;
      padding: .3rem .4rem;
    }

  }

  .card-mk-quiz--author {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    margin: .85rem 0;

    .avatar {
      width: 32px;
      height: 32px;
    }
  }

  .card-mk-quiz--price {
    color: $primary;
    text-align: right;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .card-mk-quiz--actions {
    text-align: right;
  }
}

.nav-mk-filter-types {
  .nav-item {
    .btn {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: $primary;
      font-size: 12px;
      text-transform: none;
      line-height: 1.5;
      height: auto;
      align-items: center;
      padding: 7px 11px;
      opacity: .6;
      border-radius: 0;

      .material-icons {
        float: none;
        margin: 0 0;
        line-height: 1;
      }
    }

    .active {
      opacity: 1;
      border-bottom: 2px solid $primary;
    }
  }
}

.topbar {
  .list-inline-item {
    .btn-icon {
      border: 2px solid $primary;
      width: 32px;
      height: 32px;
      .material-icons {
        font-size: 18px;
        line-height: 18px;
      }

      &:hover {
        color: $primary;
      }
    }
  }
}

.topbar-mk {
  .back-nav {
    float: left;
    height: 100%;
    padding: 12px 5px;
  }
}

.mk-page-title {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;

  h2 {
    color: $primary;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 6px;
    font-weight: bold;
  }

  .material-icons {
    font-size: 20px;
  }
}

.mk-cart--item {
  position: relative;

  .btn-remove-cart {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $primary;
    color: $white;
    border-radius: 0 0 0 50%;
  }
  .card-mk-quiz {
    .card-body {
      padding: 1.2rem 0;
    }
  }
}

.mk-cart--summary {
  margin-bottom: 30px;
  .mk-cart--summary-title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  h5 {
    color: $primary;
    font-size: 14px;
    text-align: right;
    padding-bottom: 8px;
    border-bottom: 1px dashed $primary;
  }
}

.checkout-success {
  color: $primary;
  padding: 2rem 1.2rem;
  
  .result-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid $primary;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .material-icons {
      font-size: 60px;
      line-height: 60px;
    }
  }

  h3 {
    font-size: 20px;
    color: $primary;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 30px;
  }
}

.card-share-communnity {
  text-align: center;
  width: 100%;
  max-width: 650px;
  margin: 30px auto;

  .avatar-lg {
    color: $primary;
    border: 2px solid $primary;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    
    .material-icons {
      font-size: 60px;
      line-height: 60px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;
  }

  p {
    font-size: 14px;
    text-align: justify;
  }
}

.card-stats {
  border: 2px solid $primary;
  
  .avatar {
    color: $primary;
  } 
  .card-body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .stat-data {
    flex: 1;
    font-size: 32px;
    font-weight: bold;
    text-align: right;
    color: $accent;
    padding: 0 12px;
  }

  .stat-desc {
    flex: 1;
    color: $primary;
    font-size: 12px;
    font-weight: 600;
  }
}

.mk-title {
  font-size: 18px;
  color: $primary;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}