.list-group-item {
  border-left: none;
  border-right: none;
  border-radius: 0;

  &:first-child {
    border-radius: 0;
  }
}

.list-group-item-avatar {
  min-width: 56px;
  flex-shrink: 0;
}

.list-group-item-icon {
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 32px;
  flex-shrink: 0;
  padding: 9px;

  .custom-control {
    padding-left: 1.5rem;
  }
}

.list-group-item-text {
  flex: 1 1 auto;
  margin-top: 4px;
  margin-bottom: 4px;
}

.list-group-item-text__primary {
  display: block;
  font-size: 1rem;
  font-weight: 400;
}
.list-group-item-text__secondary {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba($black, 0.6);
}