.event-progress-bar {
  .progress {
    height: .8rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $primary;
  }
  .progress-bar {
    background-color: #F9FF64;
  }
}