.questions-import-modal {
  .modal-title {
    font-size: 16px;
    font-weight: 600;
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .btn-download {
    border: 1.5px solid #ddd;
    border-radius: 0;
    margin-bottom: 11px;

    &:hover {
      background-color: rgb(230, 230, 230);
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
  }

  .file-upload {
    text-align: center;
    padding: 22px 0;

    .file-info {
      border: 1px solid #ddd;
      padding: 6px 10px;
      text-align: left;
      margin-top: 22px;
      font-weight: 600;
    }
  }

  .upload-proccess {
    margin-bottom: 20px;
    text-align: center;

    p {
      margin-bottom: .8rem;
    }
  }

  .loader { 
    width:100%; 
    height: 12px;
    margin:0 auto;
    border-radius:10px;
    border:1px solid #7E53FF;
    position:relative;
    padding:1px;

    // &:before {
    //   content:'';
    //   border:1px solid #7E53FF; 
    //   border-radius:10px;
    //   position:absolute;
    //   top:-4px; 
    //   right:-4px; 
    //   bottom:-4px; 
    //   left:-4px;
    // }

    .loaderBar { 
      position:absolute;
      border-radius:10px;
      top:0;
      right:100%;
      bottom:0;
      left:0;
      background:#7E53FF; 
      width:0;
      animation:borealisBar 2s linear infinite;
    }
  }
  
  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }
}