.stepper-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .stepper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;

        .step-container {
            border-radius: 30px;
            background-color: white;
            width: 61px;
            height: 61px;
            margin: 22.5px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            box-sizing: border-box;
            padding: 3px;
        }

        .step-selected {
            border: 2px solid $purple;
        }

        .button-step {
            background-color: white;
            width: 100%;
            height: 100%;
            color: $purple;
            font-size: 25px;
            font-family: Montserrat;
            font-weight: bold;
        }

        ._selected{
            background-color: $purple;
            color: $white;
        }
    }

    .progress-stepper {
        background-color: white;
        position: absolute;
        top: 55.5px;
        bottom: 55.5px;
        width: 3px;
        z-index: 1;

        ._stepper {
            background-color: $purple;
            width: 3px;
        }

    }
}