
.nav-backoffice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 15px;
    
    ul.nav {
      li.nav-item {
        button {
          &.nav-link {
            border: none;
          }
        }
        .nav-link {
          position: relative;
          padding: 1rem 1rem .5rem 1rem;
          color: rgba(140,138,142,0.6);
          font-size: 12px;
          font-weight: 400;
  
          &:hover {
            color: $primary;
          }
  
          &.active {
            position: relative;
            color: $primary;
            border-bottom: 2px solid $primary;
          }

          &.nav-link__highlight {
            color: $primary;
          }
        }
      }
    }
    .arrow-back-size{
      font-size: 35px;
    }
  
    .icons-materialize {
      font-size: 1.5rem;
      font-weight: 300;
      display: block;
      margin: 0 auto 10px auto;
      text-align: center;
    }
  
    .text-nav{
      font-family: $font-family-secondary;
    }
  
    .add-institute{
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }
  
    button:focus {
      outline:0;
    }
  }

