.dropdown-toggle {
  &.arrow-none {
    &:after {
      display: none;
    }
  }
} 

.dropdown-primary-up {
  .dropdown-menu {
    top: -10px !important;
    left: 0px !important;
    background-color: $primary;
    
    .dropdown-item {
      font-family: $font-family-secondary;
      color: $white;
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.08);
      }
    }

    &:after {
      top: 100%;
      right: 0%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-top-color: $primary;
      border-width: 8px;
      margin-right: 8px;
    }
  }
}

.dropdown-primary-down {
  .arrow-down {
    display: none;
  }
  .dropdown-menu {
    top: 10px !important;
    left: 0px !important;
    background-color: $primary;
    
    .dropdown-item {
      font-family: $font-family-secondary;
      color: $white;
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.08);
      }
    }

    &:after {
      top: -16px;
      right: 0%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-bottom-color: $primary;
      border-width: 8px;
      margin-right: 8px;
    }
  }
}