/// fonts ///

//font-upper
.text-upper{
    text-transform: uppercase;
}

//letter-spacing
.spacing-125{
    letter-spacing: 1.25px;
}

//line-height
.lh-22{
    line-height: 22px;
}

//font-size
.fs-12{
    font-size: 12px;
}

.fs-14{
    font-size: 14px;
}

.fs-16{
    font-size: 16px;
}

.fs-18{
    font-size: 18px;
}

.fs-22{
    font-size: 22px;
}

//font-type

.f-primary{
    font-family: $font-family-primary;
}
.f-secondary{
    font-family: $font-family-secondary;
}
/// colors ///

.color-primary{
    color: $primary ;
}
.color-secondary{
    color: $primary;
}
.color-white{
    color: $white ;
}
.color-gray-title{
    color: $accent;
}
.color-gray-subtitle{
    color: $gray-600;
}
.color-black-opacity{
    color: rgba($color: #000000, $alpha: .6);
}
