.main-navbar {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 0 1.25rem;

    .nav-back {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;

      a {
        position: relative;
        padding: 1rem 1rem .5rem 1rem;
        color: #7E53FF;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .nav-link {
      color: $gray-600;
      font-size: 12px;
      padding: 0.75rem 0.75rem 0.3rem;

      &:hover {
        color: $primary;
      }
      
      &.active {
        color: $primary;
        border-bottom: 2px solid $primary;
      }

      .material-icon {
        font-size: 24px;
      }
    }

    .navbar-title {
      h4 {
        display: inline-block;
        font-size: 18px;
        margin-bottom: 0;
      }
      .dropdown-primary-up,
      .dropdown-primary-down {
        display: inline-block;
        width: 36px;
      }
    }
  }

  .navbar-primary {
    .navbar-search {
      flex: 1;
      padding: 0 1.6rem;
      .search-container {
        max-width: 600px;
        float: right;
      }
    }
  }

  .navbar-institute-groups {
    .navbar-search {
      flex: 1;
      padding: 0 1.6rem;
      .search-container {
        max-width: 600px;
        float: right;
      }
    }
    .navbar-select-institute {
      flex: 1;
      .form-group {
        margin-bottom: 0;
      }
    }
  }

  .navbar-quiz {
    .navbar-search {
      flex: 1;
      padding: 0 1.6rem;
      .search-container {
        max-width: 600px;
        float: right;
      }
    }
    .navbar-title {
      flex: 1;
    }
  }

  .navbar-groups {
    .navbar-search {
      flex: 1;
      padding: 0 1.6rem;
      .search-container {
        max-width: 600px;
        float: right;
      }
    }
    .navbar-title {
      flex: 1;
    }
  }

  .navbar-campaign {
    .navbar-title {
      flex: 1 1 0;
    }
  }
}

.navtab-references {
  margin-bottom: 20px;
  .nav-link {
    color: $gray-600;
    font-size: 12px;
    padding: 0.75rem 0.75rem 0.3rem;

    &:hover {
      color: $primary;
    }
    
    &.active {
      color: $primary;
      border-bottom: 2px solid $primary;
    }

    .material-icon {
      font-size: 24px;
    }
  }

  .nav-item {
    button {
      background-color: transparent;
      border: none;
    }
  }
}

.nav-filters {
  display: inline-flex;
  font-size: 12px;
  button {
    &.nav-link {
      border: none;
      background-color: transparent;
      color: $gray-600;

      .material-icon {
        color: $gray-600;
      }

      &:hover,
      &.active {
        color: $primary;
        
        .material-icon {
          color: $primary;
        }
      }
    }
  }
}

.nav-mk-featured {
  .nav-item {
    .nav-link {
      font-size: 13px;
      text-transform: uppercase;
      padding: 2px 0 3px 10px;
      margin-bottom: 6px;
    }
    .active {
      font-weight: 600;
      border-left: 3px solid $primary;
    }
  }
}
