.modal-associate-subheader {
  .selections {
    font-size: 16px;
    color: $gray-600;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid $gray-400;
    padding-bottom: 1.3rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 16px;
    color: $gray-600;
  }
}
.list-quizzes {
  .custom-checkbox {
    padding-left: 1.6rem;
  }

  li {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $gray-400;

    .custom-control-label {
      display: block;
      font-size: 16px;
      font-weight: 600;
    }

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
}