$border-list: 5px;
.campaign-ranking {
  .list-group-item {
    position: relative;
    border-radius: 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 2px 0 rgba(126,83,255,0.14), 0 3px 1px -2px rgba(126,83,255,0.12), 0 1px 5px 0 rgba(126,83,255,0.2);
  }

  .ranking-top {
    border: 2px solid $primary;
  }
}