.search-container {
  position: relative;
  font-size: 14px;
  width: 100%;

  .search-icon{
    color: $primary;
  }

  & label {
    position: absolute;
    left: 12px;
    top: 6px;
    max-width: 100%;
  }

  & input::placeholder { 
    color: $gray-600;
    font-family: $font-family-primary;
    font-size: 14px;
  }

  & input {
    color: $dark;
    font-family: $font-family-primary;
    &.search-input:focus{
      outline:0px;
    } 
    &.search-input:hover,  &.search-input:focus{
      box-shadow: none;
    }

    &.search-input {
      padding: 5px 34px;
      border: 1px solid $primary;
      width: 100%;
      border-radius: 2.5rem;
      background-color: $white;
    }
  }

  & .search-btn-clear {
    position: absolute;
    right: 8px;
    top: 5px;
    color: $gray-500;
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0 5px;

    &:hover {
      cursor: pointer;
    }
  }
}