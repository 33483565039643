.section-header {
  display: flex;
  padding: 11px 0;
  margin-bottom: 15px;

  h2 {
    flex: 1;
    font-size: 16px;
    color: $primary;
    font-weight: 600;
  }

  .btn {
    margin: 0 5px;
  }
}