.card-wizard-step1 {
  background-color: $primary;
  color: $white;
  padding-bottom: 0;
  
  p {
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.select-btns-rounds {
  background-color: $white;
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

