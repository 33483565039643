.card-institute:hover,
.card-groups:hover,
.card-quiz:hover,
.card-battle:hover,
.card-challenge:hover {
  box-shadow: 0 8px 10px 1px rgba(125, 83, 255, 0.14), 0 3px 14px 2px rgba(125, 83, 255, 0.12), 0 5px 5px -3px rgba(125, 83, 255, 0.2);
}

.card-institute,
.card-groups,
.card-quiz,
.card-battle,
.card-challenge,
.card-quiz-add,
.campaign-card,
.team-challenge-card {
  background-color: $white;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(125, 83, 255, 0.14), 0 3px 1px -2px rgba(125, 83, 255, 0.12), 0 1px 5px 0 rgba(125, 83, 255, 0.2);

  .card-title {
    font-family: $font-family-primary;
    font-weight: 600;
    font-size: 18px;
    color: $accent;
  }

  .card-title.quiz-data {
    color: $primary;
    font-family: $font-family-primary;
    font-weight: 600;
    line-height: 22px;
    cursor: auto;
  }

  .nav-line {
    border-top: 1px solid $black;
    opacity: .12;
  }

  .data-stats {
    li {
      float: left;
      text-align: left;
      padding: 10px 0;
      margin-right: 10px;
    }

    .title-stats {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
      color: $gray-600;
      font-family: $font-family-primary;
    }

    .number-stats {
      display: block;
      font-size: 14px;
      font-weight: 600;
      font-family: $font-family-primary;
      color: $primary;
    }
  }

  .card-body {
    cursor: pointer;
  }

  .card-footer {
    padding: 0.5rem 1.25rem;
  }
}

.card-student {
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: transparent;
  border: none;

  .card-title {
    color: #7B7B7B;
    font-size: 16px;
    font-family: $font-family-primary;
    font-weight: 600;
  }

  .card-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1.1rem .8rem;

    ul {
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .text-card-student {
    font-family: $font-family-primary;
    color: #7B7B7B;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: .3rem;
  }

  .card-image-student {
    padding: 0 1rem;
    img {
      max-width: 100%;
      width: 80px;
      border-radius: 50%;
    }
  }
}

.card-quiz {
  .card-title {
    font-size: 16px;
  }
  .card-subtitle {
    color: #8C8A8E;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .text-quiz-card {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 600;
    color: $primary;
    font-family: $font-family-primary;
  }

  .card-body {
    padding-bottom: 0.3rem;
    .data-stats {

    }
  }

  .quiz-community {
    color: rgba($primary, .5);
    padding: 12px 6px;
    text-align: right;
    .material-icons {
      font-size: 30px;
      margin-right: 5px;
    }
  }
}


//card questions
.card-form-question {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
  }

  .card-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    .material-icons {
      color: $primary;
      margin-right: 10px;
    }
    span {
      font-size: 18px;
      color: $accent;
    }
  }

  .card-actions {
    .btn {
      margin-left: 10px;
    }
  }
}

.card-detail-add,
.card-reference-add {
  background-color: $primary;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(125, 83, 255, 0.14), 0 3px 1px -2px rgba(125, 83, 255, 0.12), 0 1px 5px 0 rgba(125, 83, 255, 0.2);
  cursor: pointer;

  .add-question-card {
    display: flex;
    justify-content: flex-start;
    color: $white;
    align-items: center;

    i {
      font-size: 30px;
    }
  }
}

.card-detail-add:hover,
.card-reference-add:hover {
  box-shadow: 0 8px 10px 1px rgba(125, 83, 255, 0.14), 0 3px 14px 2px rgba(125, 83, 255, 0.12), 0 5px 5px -3px rgba(125, 83, 255, 0.2);
}

.card-detail-question.question,
.card-add-reference.question {
  cursor: auto;
}

.quiz-detail-question {
  .card {
    background: transparent;

    .card-detail-question.detail {
      box-shadow: none;
      border: none;
      background-color: transparent;

      input {
        background: transparent;
      }
    }
  }
}



.card-detail-question,
.card-add-reference {
  background-color: $white;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(125, 83, 255, 0.14), 0 3px 1px -2px rgba(125, 83, 255, 0.12), 0 1px 5px 0 rgba(125, 83, 255, 0.2);
  cursor: pointer;

  .add-question-card {
    color: $white;
  }

  ul {
    .icon {
      width: 30px;
      height: 30px;
    }

    i {
      color: $primary;
    }

    .arrow {
      display: flex;
      cursor: pointer;

      .icon {
        display: none;
      }

      .icon:hover {
        background: $gray-400;
        border-radius: 20px;

      }
    }

    .arrow:hover {
      .icon {
        display: contents;
      }
    }

    li {
      color: $primary;

      span {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-primary;
      }

      span:hover {
        background: $gray-400;
        border-radius: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }
}

.card-detail-question:hover,
.card-add-reference:hover {
  box-shadow: 0 8px 10px 1px rgba(125, 83, 255, 0.14), 0 3px 14px 2px rgba(125, 83, 255, 0.12), 0 5px 5px -3px rgba(125, 83, 255, 0.2);
}

.border-b {
  border-bottom: 1px solid $gray-400;
}

.nav-hide {
  height: 80px;
}

.card-group-settings {
  background-color: $white;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(125, 83, 255, 0.14), 0 3px 1px -2px rgba(125, 83, 255, 0.12), 0 1px 5px 0 rgba(125, 83, 255, 0.2);
  
  .card-header {
    border-bottom: none;
    h4 {
      font-size: 18px;
      font-weight: 600;
      color: $gray-600;
      margin: 0.4rem 0;
    }
  }
}

.campaign-card, .team-challenge-card {
  font-size: 12px;
  text-align: center;

  h4 {
    font-size: 20px;
    color: $primary;
    margin-top: 1rem;
  }
  
  h5 {
    font-size: 13px;
    color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: .5rem;
    }
  }

  .block-stats {
    max-width: 500px;
    text-transform: uppercase;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    margin: 0 auto;
  }

  .block-status-info {
    padding: 1.4rem 0;
    .material-icons {
      color: $primary;
    }
  }
}

.card-schedule-event {
  .schedule-title {
    font-size: 16px;
  }

  .schedule-subtitle {
    font-size: 14px;
  }
}