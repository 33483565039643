.btn {
  position: relative;
  height: 36px;
  line-height: 36px;
  padding: 0 18px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;

  .material-icons {
    font-size: 1.3rem;
    line-height: inherit;
    float: left;
    margin-right: 12px;
  } 
  
  &.btn-sm {
    font-size: 12px;
    padding:  0 11px;
    .material-icons {
      font-size: 1rem;
      margin-right: 8px;
    }
  }
}

.btn-material-icon  {
  display: flex;
  align-items: center;
  
  > i {
    font-size: 18px;
    margin-right: 5px;
  }
}

.btn-rounded {
  border-radius: 2.5rem;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 3px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }  
}

.btn-icon {
  flex: 0 0 auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
  padding: 10px;
  margin: 0;
  overflow: visible;
  font-size: 20px;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  position: relative;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  height: auto;
  line-height: inherit;

  .material-icons {
    margin: 0;
    float: none;
    font-size: 20px;
    line-height: 20px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
}