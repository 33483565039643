.groups{
    .select-institute{
        select{
            border: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            box-sizing: border-box;
            border-radius: 0;
            font-family: $font-family-secondary;
            background: transparent;
          }
          select:focus{
            -webkit-box-shadow: none;
            box-shadow: none;
          }
    }
}

.groups-detail {
  .nav-backoffice {
    justify-content: flex-start;
  }

  .nav {
    .nav-item {
      a {
        &.nav-link__highlight {
          color: $primary !important;
        }
      }
    }
  }

  .nav-wrap-title {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    .nav-title {
      color: #7B7B7B;
      font-size: 20px;
    }
    .nav-pin {
      font-size: 16px;
      color: $primary;
      .pin-label {
        color: #8C8A8E;
      }
      .pin-code {
        padding: 0 8px;
        color: #FF537E;
      }
      .material-icons {
        font-size: 16px;
      }
    }
  }

  .search-bar{
    button{
      text-transform: uppercase;
    }
    button:focus{
      box-shadow: none;
    }
  }
  .nav-group-detail{
    display: flex;
    flex-direction: column;
    .nav-group-detail-title{
      font-family: $font-family-primary;
      font-size: 20px;
      color: $accent;
    }
    .info-group-nav{
      display: flex;
      justify-content: space-between;
      span{
        font-family: $font-family-primary;
        font-size: 16px;
        color: $primary;
      }
      i{
        color: $primary;
        font-size: 16px
      }
      .title-pin{
        color: $accent;
      }
    }
  }
}

.groups-alumns,.groups-alumns-details,.groups-categories,.groups-battles{
  .nav-backoffice{
    flex-direction: column;
  }
  .nav{
    width: 100%;
    justify-content: space-between;
  }
  .nav-group-detail{
    display: flex;
    flex-direction: column;
    .nav-group-detail-title{
      font-family: $font-family-primary;
      font-size: 20px;
      color: $accent;
    }
    .info-group-nav{
      display: flex;
      justify-content: space-between;
      span{
        font-family: $font-family-primary;
        font-size: 16px;
        color: $primary;
      }
      i{
        color: $primary;
        transform: rotate(180deg);
      }
      .title-pin{
        color: $accent;
      }
    }
  }
}

.nav-line{
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 2px solid $font-family-primary;
  width: 100%;
}
.nav-line-gray{
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid $gray-200;;
  width: 100%;
}

.arrow-back{
  font-size: 35px;
}